html {
  font: normal .833333vw SegoeUI;

  @media screen and (max-width: $laptop-l) {
    font-size: 1vw;
  }

  @media screen and (max-width: $laptop-m) {
    font-size: 14px;
  }

  body {
    position: relative;
    min-height: 100vh;

    .container {
      @media screen and (min-width: $laptop-s + 1) {
        width: calc(90% + 30px);
        max-width: 100%;
      }

      @media screen and (min-width: $laptop-l) {
        width: calc(75% + 30px);
      }
    }
  }

  a {
    color: inherit;

    &:focus {
      outline: none;
    }

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

img {
  max-width: 100%;
}

section {
  margin-bottom: 2em;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.main-wrapper {
  padding-bottom: 5em;
}