.apartments-status {
  margin: 1.875em 0;
  $font-size: 15.5;

  div {
    font-size: $font-size/16 + em;
    font-weight: 600;
  }

  span {
    width: 17/$font-size + em;
    height: 17/$font-size + em;
    border-radius: 6/$font-size + em;
    margin-right: 5/$font-size + em;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;

    &.available {
      background: $green;
    }

    &.disabled {
      background: $border-grey;
    }

    &.booked {
      background: $yellow2;
    }

    &.sold {
      background: $red;
    }

    &.current {
      background: lightskyblue;
    }
  }
}