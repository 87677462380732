.calculator {
  .input-group {
    flex-wrap: nowrap;

    &:not(:last-child) {
      margin-bottom: 1.875em;

      @media screen and (max-width: $tablet) {
        margin-bottom: 1em;
      }
    }

    @media screen and (max-width: $tablet) {
      flex-wrap: wrap;

      label {
        width: 100%;
        padding-bottom: .5em;
      }
    }

    label {
      flex-grow: 1;
      margin-right: 1.5em;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 0;
    }

    input,
    input[type="date"] {
      flex-shrink: 0;
      font-size: 14/16+em;
      width: 241/14+em;
      height: 38/14+em;
      box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21);
      background-color: #ffffff;
      border: 1px solid $border-grey;
      padding: 0 16/14+em;
      font-family: SegoeUI;

      @media screen and (max-width: $mobile-l) {
        width: 100%;
      }

      &[disabled] {
        color: #9ea0a5;
        background: linear-gradient(to top, #f1f1f1, #e6e6e6);
      }
    }

    input[type=date]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
    }
  }

  .range-single {
    &:not(:last-child) {
      margin-bottom: 1.875em;

      @media screen and (max-width: $tablet) {
        margin-bottom: 1em;
      }
    }

    .input-group {
      margin-bottom: 0;
    }

    .range-slider-wrapper {
      margin-top: 10/16+em;

      @media screen and (max-width: $tablet) {
        display: none;
      }
    }
  }

  .details-list--calculator {
    .input-group {
      display: block;
      label{
        line-height: initial;
        font-weight: initial;
      }
      p{
        margin-bottom: .5em;
      }
      input{
        width: 100%;
      }
    }
  }

  &__toggle {
    font-size: 1.125em;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    margin-bottom: 1.5em;
    cursor: pointer;

    img {
      width: 1.5em;
      margin-right: .25em;
    }

    i {
      margin-left: .25em;
      transition: .3s;
      display: inline-block;
      font-size: .8em;
    }

    &:hover {
      img {
        opacity: .75;
      }
    }

    &.toggled {
      i {
        transform: rotate(180deg);
      }
    }
  }

  &__desc {
    display: none;
  }
}
