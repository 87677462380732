.cart-item {
  margin-top: 40/16 + em;
  margin-bottom: 40/16 + em;
  position: relative;
  overflow: visible;

  &.disabled .row {
    opacity: .2;
    transition: .3s;
  }

  .row:not(:first-child) {
    margin-top: 1em;
  }

  strong {
    font-family: SegoeUI-Bold;
  }

  .icon-close {
    background: $color-secondary;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%) translateY(-50%);
    transition: .3s;
    cursor: pointer;
    font-size: 0.9125em;
    line-height: 2.2em;
    width: 2.2em;
    text-align: center;

    @media screen and (max-width: $mobile-l) {
      right: 2em;
    }

    &:hover {
      background: $color-secondary-hover;
    }
  }

  .timer {
    background: $red;
    color: #fff;
    padding: 0 1.5em .1em;
    line-height: 2em;
    border-radius: 12.5/16 + em;
    position: absolute;
    right: 36/16 + em;
    top: 0;
    transform: translateY(-50%);

    @media screen and (max-width: $mobile-l) {
      position: relative;
      margin-top: 1em;
      top: auto;
      transform: none;
      right: auto;
    }
  }
}
