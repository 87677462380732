body.ie-only {
  .filter:not(.filter-mini) {
    .container > .d-flex > div {
      flex: auto;
      //width: auto;

      &:first-child {
        width: 25%!important;
        flex: auto;
      }

      &:nth-of-type(2) {
        width: 25%;
      }

      &:nth-of-type(3) {
        width: 15%;
      }

      &:nth-of-type(4) {
        width: 15%;
      }

      &:nth-of-type(5) {
        width: 20%;
      }

      input[type="text"],
      input[type="number"],
      input[type="tel"],
      input[type="email"],
      input[type="password"],
      textarea {
        height: 75/16 + em;
      }
    }
  }


}