.regular-carousel {
  position: relative;

  .slick-arrow {
    position: absolute;
    height: 100%;
    background: rgba(255,255,255,.8);
    top: 0;
    width: 74/16 + em;
    z-index: 10;
    cursor: pointer;
    transition: .3s;

    @media screen and (max-width: $tablet) {
      font-size: .5em;
    }

    &:hover {
      background: rgba(255,255,255,.9);
    }

    &:before {
      font-size: 36/16 + em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &.icon-left {
      left: 0;
    }

    &.icon-right {
      right: 0;
    }
  }

  &.height-25 {
    .item {
      position: relative;
      padding-bottom: 25%;
      overflow: hidden;

      a {
        display: block;
      }

      @media screen and (max-width: $laptop-s) {
        padding-bottom: 50%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include object-fit(cover);
      }
    }
  }

  &.height-50 {
    .item {
      position: relative;
      padding-bottom: 40%;
      overflow: hidden;

      a {
        display: block;
      }

      @media screen and (max-width: $laptop-s) {
        padding-bottom: 50%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include object-fit(cover);
      }
    }
  }
}

body:not(.ie-only) {
  .regular-carousel {
  }
}