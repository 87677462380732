.apartment-detail {
  overflow: hidden;

  @media screen and (max-width: $tablet) {
    margin-top: 30px;
  }

  .apartments-scheme {
    border: 2px solid $border-grey;
    border-radius: 15/16 + em;
    padding: (7/16 + em);
    height: 100%;

    &>div {
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: (7/16 + em);
      max-height: 0;
      min-height: 20em;

      @media screen and (max-width: $tablet) {
        max-height: 20em !important;
        overflow-x: auto;
      }

      &::-webkit-scrollbar-track {
        background-color: $border-grey;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: $border-grey;
        border-radius: 3px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-secondary;
        border-radius: 3px;
      }
    }

    .floor {
      display: flex;

      @media screen and (max-width: $tablet) {
        //justify-content: center;
      }

      &.title {
        .floor-num span {
          font-size: .8em;
        }

        .floor-apts {
          border-left: none;
        }
      }

      .floor-num,
      a {
        $font-size: 15.5;
        font-size: $font-size/16 + em;
        width: 35/$font-size + em;
        line-height: 35/$font-size + em;
        text-align: center;
        margin-bottom: 7/$font-size + em;
        border-radius: 12/$font-size + em;
        font-weight: 600;
        flex-shrink: 0;

        &:not(:last-child) {
          margin-right: 7/$font-size + em;
        }
      }

      a {
        transition: .3s;

        &[data-status="available"] {
          background: $green;

          &:hover {
            background: $green-hover;
          }
        }

        &[data-status="disabled"] {
          background: $border-grey;
          pointer-events: none;
        }

        &[data-status="sold"] {
          background: $red;
          pointer-events: none;
        }

        &[data-status="current"] {
          background: lightskyblue;
          pointer-events: none;
        }

        &[data-status="booked"] {
          background: $yellow2;
          pointer-events: none;

          &:hover {
            background: $yellow2-hover;
          }
        }
      }

      .floor-apts {
        display: flex;
        border-left: 2px solid $border-grey;
        padding-left: 14/16 + em;
      }

      &:last-child {
        .floor-num, a {
          margin-bottom: 0;
        }
      }
    }
  }

  .apartment-image {
    @media screen and (max-width: $tablet) {
      margin-top: 30px;
    }

    img {
      max-height: 350/16 + em;
    }
  }
}