@font-face {
  font-family: 'SegoeUI';
  font-display: auto;
  src: url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI/SegoeUI.eot?#iefix') format('embedded-opentype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI/SegoeUI.woff') format('woff'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI/SegoeUI.ttf')  format('truetype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI/SegoeUI.svg#SegoeUI') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI-SemiBold';
  font-display: auto;
  src: url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-SemiBold/SegoeUI-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-SemiBold/SegoeUI-SemiBold.woff') format('woff'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-SemiBold/SegoeUI-SemiBold.ttf')  format('truetype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-SemiBold/SegoeUI-SemiBold.svg#SegoeUI-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI-Light';
  font-display: auto;
  src: url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Light/SegoeUI-Light.eot?#iefix') format('embedded-opentype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Light/SegoeUI-Light.woff') format('woff'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Light/SegoeUI-Light.ttf')  format('truetype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Light/SegoeUI-Light.svg#SegoeUI-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI-Bold';
  font-display: auto;
  src: url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Bold/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Bold/SegoeUI-Bold.woff') format('woff'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Bold/SegoeUI-Bold.ttf')  format('truetype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Bold/SegoeUI-Bold.svg#SegoeUI-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI-Black';
  font-display: auto;
  src: url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Black/SegoeUIBlack.eot?#iefix') format('embedded-opentype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Black/SegoeUIBlack.woff') format('woff'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Black/SegoeUIBlack.ttf')  format('truetype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/SegoeUI-Black/SegoeUIBlack.svg#SegoeUIBlack') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'icomoon';
  font-display: auto;
  src:  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/icoMoon/icomoon.eot?wknem9');
  src:  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/icoMoon/icomoon.eot?wknem9#iefix') format('embedded-opentype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/icoMoon/icomoon.ttf?wknem9') format('truetype'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/icoMoon/icomoon.woff?wknem9') format('woff'),
  url('https://s3.eu-north-1.amazonaws.com/expoapartments/assets/fonts/icoMoon/icomoon.svg?wknem9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
  content: "\e90c";
}
.icon-m-up:before {
  content: "\e900";
}
.icon-m-right:before {
  content: "\e901";
}
.icon-m-left:before {
  content: "\e902";
}
.icon-m-down:before {
  content: "\e903";
}
.icon-search:before {
  content: "\e904";
}
.icon-up:before {
  content: "\e906";
}
.icon-right:before {
  content: "\e907";
}
.icon-down:before {
  content: "\e908";
}
.icon-close:before {
  content: "\e905";
}
.icon-up:before {
  content: "\e906";
}
.icon-right:before {
  content: "\e907";
}
.icon-down:before {
  content: "\e908";
}
.icon-left:before {
  content: "\e909";
}
.icon-iphone:before {
  content: "\e90a";
}
.icon-cart:before {
  content: "\e90b";
}
