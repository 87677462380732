.details-list {

  p {
    &:not(:last-child) {
      margin-bottom: 1em;
    }

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      font-family: SegoeUI-Black;
      font-size: 1.25em;
    }

    span {
      font-weight: 600;
    }

    &.hint {
      color: $grey;
      font-weight: 600;
    }
  }

  &--calculator {
    p {
      strong {
        font-size: 1em;
        font-family: SegoeUI-Bold;
      }
    }
  }
}

.details-list-outer {
  @media screen and (max-width: 992px) {
    margin-top: 30px;
  }

  img {
    width: 134/16+em;
    margin: 1em auto;
    display: block;
  }
}
