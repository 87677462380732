header {
  //padding: (73/16 + em) 0;
  margin-bottom: 7/16 + em;
  //padding: (48/16 + em) 0;
  padding: 1em 0;

  @media screen and (max-width: $laptop-s) {
    padding: 2.4em 0;
  }

  .logo {
    display: flex;
    align-items: center;

    &:not(:nth-of-type(2)) {
      margin-right: 1em;
      border-right: 1px solid $yellow;
      padding-right: 1em;
    }

    img {
      max-height: 48/16 + em;

      &+span {
        margin-left: 1em;
        color: $color-primary;
      }
    }
  }

  .main-title {
    align-self: center;
    margin: 0 auto;
    //margin-top: 2em;

    @media screen and (max-width: $mobile-m) {
      margin-left: 0;
    }

    h1 {
      font-size: 1.5em;
      margin-bottom: 0;
      color: $yellow;
      font-family: SegoeUI-SemiBold;

      @media screen and (max-width: $tablet) {
        font-size: 1.8em;
      }

      @media screen and (max-width: $mobile-l) {
        font-size: 1.7em;
      }

      @media screen and (max-width: $mobile-m) {
        font-size: 1.625em;
      }
    }
  }

  .right-block {
    //margin-left: auto;
    margin-right: 3em;
  }

  .calculator-icon {
    width: 2.1em;
    transition: .3s;
    margin-right: 1.6em;
    flex-shrink: 0;

    &:hover {
      opacity: .75;
    }
  }

  .contacts {
    display: flex;
    align-items: center;
    margin-right: 3em;

    span:first-child {
      margin-right: .25em;
    }

    span:last-child {
      //font-size: 1.125em;
      //line-height: 1.56;
      font-family: SegoeUI-SemiBold;

      a {
        white-space: nowrap;
      }
    }
  }

  .cart {
    position: relative;

    .count {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(25%) translateY(25%);
      background: $red;
      border-radius: 50%;
      font-size: .875em;
      width: 25/14 + em;
      line-height: 25/14 + em;
      color: #fff;
      font-family: SegoeUI-SemiBold;
      text-align: center;
    }
  }

  .languages {
    text-align: center;

    a {
      font-family: SegoeUI-SemiBold;
      opacity: .5;
      transition: .3s;

      &.active {
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  [class^="icon-"] {
    color: $yellow;
    font-size: 2em;
  }

  .mob-none {
    @media screen and (max-width: $laptop-s) {
      display: none !important;
    }
  }
}