body {
  .chosen-container {
    width: 100% !important;
    //text-transform: uppercase;
    font-size: 1em;
  }

  .chosen-container-single .chosen-single input[type="text"] {
    padding: 0;
  }

  .chosen-container-single .chosen-single {
    background: #fff;
    box-shadow: none;
    border: 1px solid $light-grey;
    border-radius: 0;
    height: auto;
    padding: 0 ($padding-side/16 + em);
    color: #333;
    line-height: $line-height/16 + em;
    //white-space: normal;

    span {
      //white-space: normal;
    }
  }

  .chosen-container-multi .chosen-choices {
    padding: 0;
    box-shadow: none;
    background-image: none;
    //border: 1px solid $light-grey;
    //min-height: 51px;
    overflow-x: auto;
    cursor: pointer;
    border: none;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: column;
  }

  .chosen-container-multi .chosen-choices li.search-choice, .chosen-container-multi .chosen-choices li.search-field {
    background: #fff;
    box-shadow: none;
    border-radius: 0;
    border: none;
    height: auto;
    line-height: 1.1;
    padding: 1em 1.5em;
    color: #333;
    float: none;
    display: inline-block;
    margin: 0;
    vertical-align: top;
    width: 100%;

    &:not(last-child) {
      border-bottom: 1px solid $light-grey;
    }
  }

  .chosen-container-multi .chosen-choices li.search-field {
    border-right: none !important;

    &:first-child {
      width: 100%;

      input[type=text] {
        width: 100% !important;
      }
    }
  }

  .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    height: auto;
    margin: 0;
    line-height: 1.1;
    display: block;
    color: $grey;
    background: #fff;
    font-family: inherit;
  }

  .chosen-container.chosen-container-single.chosen-container-active.chosen-with-drop > a,
  .chosen-container.chosen-container-single.chosen-container-active.chosen-with-drop .chosen-drop,
  .chosen-container.chosen-container-multi.chosen-with-drop.chosen-container-active .chosen-choices {
    border-color: $grey;
  }

  .chosen-container-active.chosen-with-drop .chosen-single {
    background: #fff;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .chosen-container-active.chosen-with-drop .chosen-single, .chosen-container.chosen-with-drop .chosen-drop {
    border-color: $grey;
    border-width: 1px;
  }

  .chosen-container-single .chosen-single span {
    //overflow: visible;
  }

  .chosen-container-single .chosen-single div b {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 1em;
    left: 50%;
    color: #333;
    background: none !important;

    &:after {
      content: '\e903';
      font-family: 'icomoon';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .chosen-container-active.chosen-with-drop .chosen-single div b {
    &:after {
      content: '\e900';
      //font-family: 'icomoon';
    }
  }

  .chosen-container .chosen-results {
    font-size: 1em !important;
  }

  .chosen-container-single .chosen-single div {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 55px;
  }

  .chosen-container-single .chosen-drop {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    background: #f9f9f9;
  }

  .chosen-container .chosen-results {
    padding: 0;
    padding-bottom: .5em;
    margin: 0;

    &::-webkit-scrollbar-track {
      background-color: $border-grey;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: $border-grey;
      //border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-secondary;
      //border-radius: 5px;
    }
  }

  .chosen-container .chosen-results li {
    padding: .5em 1.5em;
    color: #333;
    line-height: 1;
  }

  .chosen-container-single .chosen-default {
    color: $grey;
  }

  .chosen-container .chosen-results li.highlighted {
    background: $color-secondary;
  }

  .chosen-container-single .chosen-search {
    //display: none;
    padding: 0;
    margin: 0;
    display: block;

    input[type=text] {
      margin: 0;
      border: none;
      border-top: 1px solid $grey;
      border-bottom: 1px solid $grey;
      //padding: 1em 2.5em 1em 1.5em;
      padding: 0 ($padding-side/16 + em);
      background: none;
      line-height: $line-height/16;
      background: #fff;
      font-family: SegoeUI-SemiBold;
    }

    &:after {
      content: '\e904';
      font-family: icomoon;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      width: 1.5em;
      position: absolute;
      right: .5em;
    }
  }

  .no-search + .chosen-container-single .chosen-search {
    display: none;
  }
}