.parking-detail {
  .chosen-outer {
    border-left: 2px solid $border-grey;
    height: 100%;

    @media screen and (max-width: $laptop-s) {
      border-left: none;
    }
  }

  .chosen.opened + .chosen-container {
    //max-height: 440px;
    //height: 100%;
    //display: flex;
    //flex-direction: column;

    &.red {
      .chosen-single:after, .chosen-choices:after {
        opacity: 1;
        z-index: 1;
      }
    }

    .chosen-single, .chosen-choices {
      //flex-shrink: 0;
      border-width: 2px;
      border-color: $border-grey;
      border-top: none;
      border-right: none;
      border-left: none;
      position: relative;
      //border-bottom: none;
      //
      //b {
      //  display: none;
      //}

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid $red;
        border-top-right-radius: 1.25em;
        opacity: 0;
        z-index: -1;
      }

      li:nth-child(3) {
        display: none!important;
      }
    ;
    }

    .chosen-drop {
      //position: relative;
      //top: 0;
      //height: 100%;
      //display: flex;
      //flex-direction: column;
      box-shadow: none;
      border-width: 2px;
      border-color: $border-grey !important;
      border-bottom: none;
      border-right: none;
      border-left: none;
      background: #fff;
      margin-top: 0;
      //
      .chosen-search input[type=text] {
        border-width: 2px;
        border-color: $border-grey !important;
        border-top: none;
      }
      //
      .chosen-results {
        //  height: 100%;
        //  max-height: 100%;
        //
        li {
          padding-top: .8em;
          padding-bottom: .8em;

          &:not(:last-child) {
            border-bottom: 2px solid $border-grey;
          }
        }
      }
    }
  }

  .parking-image {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      display: table;
      margin: 0 auto;
    }

    img {
      max-height: 400/16 + em;
      display: block;
      margin: 0 auto;
    }
  }
}

#height {
  margin: 0;
  border: none;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  padding: 1em 2.5em 1em 1.5em;
  background: 0 0;
  line-height: 1.1;
  background: #fff;
  font-family: SegoeUI-SemiBold;
  border-width: 2px;
  position: absolute;
  left: -4444px;
}
