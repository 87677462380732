.range-single {
  div.slider-range {
    height: .25em;
    //margin-top: 2em;
    margin-top: 1em;
    border: none;
    background: #e5e5e5;
  }

  .ui-slider-range.ui-corner-all.ui-widget-header {
    background: $color-secondary;
  }

  span.ui-slider-handle.ui-corner-all.ui-state-default {
    border-radius: 50%;
    border-bottom-left-radius: 0;
    background: $color-secondary;
    border: none;
    outline: none !important;
    top: -0.5em;
    cursor: pointer;
  }

  .separators {
    position: relative;
    width: 100%;
    padding-bottom: 1.5em;

    &>span {
      display: block;
      width: 3/16 + em;
      height: 13/16 + em;
      background: $light-grey;
      position: absolute;
      top: -8/16 + em;

      &:not(:first-child), &:not(:last-child) {
        transform: translateX(-50%);
      }

      span {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: .625em;
        margin-top: .5em;
        white-space: nowrap;

        @media screen and (max-width: $laptop-l) {
          font-size: .725em;
        }
      }
    }
  }

  .range-slider-wrapper {
    background: #e2e5ed;
    border-radius: 4px;
    padding: .5em 2em;

    div.slider-range {
      background: #fff;
    }

    .separators > span {
      background: #fff;
    }
  }
}