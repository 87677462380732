.bordered {
  border-radius: 1.25em;
  background-color: #ffffff;
  border: solid 2px $border-grey;
  height: 100%;
  padding: 2.5em;
  overflow: hidden;

  @media screen and (max-width: $tablet) {
    padding: 1.5em;
  }

  &.no-pad {
    padding: 0;
  }
}