.plain-text {
  h4 {
    font-size: 1.375em;
    font-family: SegoeUI-Bold;
    margin-top: .5em;
    margin-bottom: 30/22 + em;
  }

  p {
    font-family: SegoeUI-SemiBold;
  }
}