.menu-btn {
  position: fixed;
  top: 1em;
  right: 1em;
  background: $color-secondary;
  padding: 1em !important;
  z-index: 999;
  //border-radius: 1em;
  font-size: 0.75em;

  @media screen and (min-width: $laptop-s + 1) {
    display: none;
  }

  @media screen and (max-width: $tablet) {
    top: 2.5em;
  }

  @media screen and (max-width: $mobile-l) {
    font-size: .7em;
  }

  .nav-icon {
    width: 3em;
    height: 2.5em;
    position: relative;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 0.1875em;
      width: 100%;
      background: #fff;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 1.125em;
      }

      &:nth-child(4) {
        top: 2.25em;
      }
    }

    &.opened span {
      background: #fff;

      &:nth-child(1) {
        top: 1.125em;
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 1.125em;
        width: 0;
        left: 50%;
      }
    }
  }
}
