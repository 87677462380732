.breadcrumbs {
  list-style: none;
  padding-left: 0;
  margin-top: 13/16 + em;
  margin-bottom: 32/16 + em;
  display: table;

  @media screen and (max-width: $laptop-s) {
    //margin-bottom: 1em;
  }

  li {
    display: inline-block;
    font-weight: 600;
    color: $grey;

    &:not(:last-child):after {
      content: '-';
      margin: 0 .5em;
    }

    a, span {
      transition: .3s;
    }

    a:hover {
      color: #333;
    }
  }
}