body {
  .card {
    //border-radius: 3.125em;
    border-radius: 1.875em;
    border: 2px solid $light-grey;
    //padding: 1.875em 2.5em;
    padding: 1.5em;
    margin-bottom: 30px;
    display: block;
    transition: .3s;

    &:hover {
      box-shadow: 0 10px 30px rgba(0,0,0,.2);
    }

    img {
      margin-bottom: 22/16 + em;
      //width: 222/16 + em;
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-height: 8em;
    }

    h4 {
      font-size: 22/16 + em;
      font-family: SegoeUI-Bold;
      //margin-top: 1em;
      //margin-bottom: 28/22 + em;
      margin-bottom: .5em;
    }

    p {
      margin-bottom: .5em;
    }
  }
}