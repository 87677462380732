footer {
  //padding: 2em 0;
  height: 5em;
  padding: 0;
  display: flex !important;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .copyright {
    font-family: SegoeUI-SemiBold;
    color: $color-secondary;
    text-transform: uppercase;
  }
}