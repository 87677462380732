.filter {
  margin-bottom: 16/16 + em;

  .d-flex {
    margin: 0 -15px;

    &>div {
      padding: 0 15px;
    }
  }

  .rooms {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $tablet) {
      margin-bottom: 1em;
    }

    label {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: .625em;
      }
    }

    input {
      display: none;

      &+span {
        width: $line-height/16 + em;
        line-height: $line-height/16 + em;
        border: 1px solid $light-grey;
        font-weight: 600;
        transition: .15s;
        text-align: center;
        display: block;
        cursor: pointer;

        &:hover {
          background: $light-grey;
        }
      }

      &:checked+span {
        background: $color-secondary;
        border-color: $color-secondary;
        color: #fff;
      }
    }
  }

  .button-primary {
    min-width: 270/18 + em;
  }

  .range-slider {
    //margin-bottom: 1em;
    width: calc(100% - 1.2em);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.7em;

    .inputs {
      display: table;
      width: 100%;
      margin-bottom: 1em;

      &>div {
        float: left;
        width: calc(50% - .625em/2);
        display: table;
        border-bottom: 1px solid $light-grey;
        padding-bottom: .2em;

        &:last-child {
          float: right;
        }

        &>* {
          display: table-cell;
          vertical-align: bottom;
        }

        .grey {
          width: 1%;
          padding-right: .5em;
        }
      }
    }

    input[type='text'] {
      padding: 0;
      border: none;
      width: 100%;
      font-family: SegoeUIBold;
      font-size: 1.125em;
      border-radius: 0;
      background: none;
    }

    div.slider-range {
      height: .25em;
      //margin-top: 2em;
      margin-top: 1em;
      border: none;
      background: #e5e5e5;
    }

    .ui-slider-range.ui-corner-all.ui-widget-header {
      background: $color-secondary;
    }

    span.ui-slider-handle.ui-corner-all.ui-state-default {
      border-radius: 50%;
      border-bottom-left-radius: 0;
      background: $color-secondary;
      border: none;
      outline: none !important;
      top: -0.5em;
      cursor: pointer;

      &:last-child {
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 0;
      }
    }

    .separators {
      position: relative;
      width: 100%;
      padding-bottom: 1.5em;

      &>span {
        display: block;
        width: 3/16 + em;
        height: 13/16 + em;
        background: $light-grey;
        position: absolute;
        top: -8/16 + em;

        &:not(:first-child), &:not(:last-child) {
          transform: translateX(-50%);
        }

        span {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          font-size: .625em;
          margin-top: .5em;

          @media screen and (max-width: $laptop-l) {
            font-size: .725em;
          }
        }
      }
    }
  }

  &__mini {
    //border-bottom: 1px solid $light-grey;

    .form-group {
      //padding: (25/16 + em) 1.875em 0;
      padding: 1em 1.125em 0;
      border: 1px solid $light-grey;
      //border-bottom: none;
      margin-bottom: 0;

      &:not(:last-child) {
        @media screen and (min-width: $tablet + 1) {
          border-right: none;
        }

        @media screen and (max-width: $tablet) {
          border-bottom: none;
        }
      }

      &>label {
        margin-bottom: 0;
      }

      select {
        border: none;
      }
    }

    .chosen-container-single .chosen-single {
      border-color: transparent !important;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: .5em;

      div b {
        top: 40%;
      }
    }

    .chosen-container .chosen-drop {
      left: 50%;
      transform: translateX(-50%) translateY(1px);
      width: calc(100% + 1.125em*2 + 2px);
    }

    .chosen-container-single .chosen-single div {
      width: 10px;
    }

    .rooms {
      height: $line-height/16 + em;
      align-items: center;

      input+span {
        //width: 30/16 + em;
        //line-height: 30/16 + em;
        width: 2.475em;
        line-height: 2.475em;
        border: none;
        background: $light-grey;
        border-radius: 50%;
      }
    }
  }
}