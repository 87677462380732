.form-group {
  &>label {
    line-height: 1;
    margin-bottom: 13/16 + em;
    color: $grey;
    width: 100%;
  }

  input[type="text"],
  input[type="number"],
  input[type="tel"],
  input[type="email"],
  input[type="password"],
  textarea {
    width: 100%;
  }

  .info-label {
    line-height: $line-height/16;
    font-weight: 600;
    display: table;
    margin: 0 auto;
  }
}

body {
  input[type="text"],
  input[type="number"],
  input[type="tel"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  textarea {
    padding: 0 ($padding-side/16 + em);
    line-height: $line-height/16 + em;
    border: 1px solid $light-grey;
    font-family: SegoeUI-SemiBold;
    -webkit-appearance: none;
    border-radius: 0;

    @media screen and (max-width: $tablet) {
      font-size: 16px;
      line-height: 4.1em;
    }

    &:focus {
      border-color: $grey;
      outline: none;
    }

    &.red {
      border-color: $red;
    }

    &.green {
      border-color: $green;
    }
  }

  textarea {
    min-height: 2.3865em;
    line-height: 1.25;
  }

  select {
    height: 4.6875em;
    width: 100%;
    border-color: $border-grey;
    outline: none !important;
    //-webkit-appearance: none;
    border-radius: 0;

    @media screen and (max-width: $tablet) {
      font-size: 16px;
      line-height: 4.1em;
    }

    &.red {
      background: #ffb7b7;
    }
  }
}

.button-primary {
  color: #fff;
  font-size: 1.125em;
  line-height: $line-height/18 + em;
  padding: 0 ($padding-side/16 + em);
  background: $color-secondary;
  transition: .3s;
  border-radius: 37.5/18 + em;
  border: none;
  cursor: pointer;
  font-family: SegoeUI-SemiBold;
  display: inline-block;
  text-align: center;

  &.wide {
    min-width: 270/18 + em;

    @media screen and (max-width: $mobile-l) {
      width: 100%;
    }
  }

  &:hover {
    color: #fff;
    background: $color-secondary-hover;
  }

  &:focus {
    outline: none;
  }

  &.gold {
    color: $yellow;
    border: 3px solid $yellow;
    background: transparent;
    line-height: 69/18 + em;

    &:hover {
      background: $yellow;
      color: #fff;
    }
  }
}

.h-primary {
  margin-top: 0;
  font-size: 42/16 + em;
  margin-bottom: 13/42 + em;
  font-family: SegoeUI-Bold;

  @media screen and (max-width: $tablet) {
    font-size: 1.625em;
    font-family: SegoeUI-SemiBold;
  }
}

.breadcrumbs {

}

.checkbox {
  display: table;
  margin-bottom: 1.875em;

  input[type="checkbox"] {
    display: none;

    &+span {
      font-family: SegoeUI-SemiBold;
      padding-left: 47/16 + em;
      position: relative;
      padding-top: .25em;
      padding-bottom: .25em;
      display: table;
      cursor: pointer;

      &:before {
        content: '';
        width: 2em;
        height: 2em;
        position: absolute;
        left: 0;
        top: 0;
        background: $border-grey;
        border-radius: 6/16 + em;
      }

      &:after {
        content: "\e90c";
        font-family: icomoon;
        position: absolute;
        left: .5em;
        top: .25em;
        color: $green2;
        opacity: 0;
        transition: .3s;
      }

      a {
        color: $green2;
        text-decoration: underline;
        font-family: SegoeUI-Bold;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &:checked + span:after {
      opacity: 1;
    }

    &.red + span:before {
      border: 2px solid $red;
    }
  }
}

.radio {
  display: table;
  margin-bottom: 1.875em;

  input[type="radio"] {
    display: none;

    &+span {
      font-family: SegoeUI-SemiBold;
      padding-left: 47/16 + em;
      position: relative;
      padding-top: .25em;
      padding-bottom: .25em;
      display: table;
      cursor: pointer;

      &:before {
        content: '';
        width: 2em;
        height: 2em;
        position: absolute;
        left: 0;
        top: 0;
        background: $border-grey;
        border-radius: 6/16 + em;
      }

      &:after {
        content: "\e90c";
        font-family: icomoon;
        position: absolute;
        left: .5em;
        top: .25em;
        color: $green2;
        opacity: 0;
        transition: .3s;
      }

      a {
        color: $green2;
        text-decoration: underline;
        font-family: SegoeUI-Bold;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &:checked + span:after {
      opacity: 1;
    }

    &.red + span:before {
      border: 2px solid $red;
    }
  }
}
