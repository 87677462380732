.menu.mobile {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,1);
  z-index: 99;
  padding: 5em 1.5em 1.5em;
  display: none;

  .logo {
    margin-bottom: 1em;
    border-right: none;
  }

  .right-block {
    font-size: 1.25em;
    justify-content: center;
    margin-right: 0;
    margin-top: 1.5em;

    @media screen and (max-width: $mobile-m) {
      font-size: 1.125em;
    }

    @media screen and (max-width: $mobile-s) {
      font-size: 1em;
    }
  }

  .languages {
    margin-top: 1.5em;
    font-size: 1.5em;
    word-spacing: 1em;
  }
}